const CONST = require('plugin_frontend_core/constants');
const cwUtil = require('plugin_frontend_core/utils/utils_cw');
const helpers = require('plugin_checkout/cart/helpers');
let previousBonusState = false;

const selectors = {
    cartError: '.cart-error',
    cartPage: '.js-cart-page',
    cheque: {
        addChequeBtn: '.js-add-cheque',
        chequeProductLine: '.js-product-card--waardecheck'
    },
    deleteCartConfirmationBtn: '.cart-delete-confirmation-btn',
    minicart: '.js-minicart',
    minicartCloseButton: '.js-minicart-close',
    minicartLink: '.js-minicart-link',
    minicartPopover: '.js-minicart .js-popover',
    minicartQuantity: '.js-minicart-quantity',
    minicartWrapper: '.minicart',
    modalBackdrop: 'body > .modal-backdrop',
    popover: '.js-popover',
    product: {
        changeQuantity: '.quantity-form > .js-quantity-changer',
        productToRemoveContainer: '.product-to-remove',
        quantityForm: '.quantity-form',
        quantityInput: '.quantity-form > .quantity',
        removeProductBtn: '.js-remove-product'
    },
    search: '.js-site-search'
};

const events = {
    countUpdate: 'count:update'
};

const $doc = $(document);
const $body = $(CONST.selectors.body);
const $minicart = $(selectors.minicart);
const $minicartLink = $(selectors.minicartLink);

let $page;
let $search;

let $minicartPopover;
let $minicartQuantity;

/**
 * @private onRemoveProduct
 * @param {MouseEvent} evt - Click Event
 */
function onRemoveProduct(evt) {
    evt.preventDefault();

    const $self = $(this);
    var actionUrl = $self.data(CONST.attributes.action);
    var productID = $self.data(CONST.attributes.pid);
    var productName = $self.data(CONST.attributes.name);
    var uuid = $self.data(CONST.attributes.uuid);

    var $deleteConfirmBtn = $(selectors.deleteCartConfirmationBtn);
    var $productToRemoveSpan = $(selectors.product.productToRemoveContainer);

    $deleteConfirmBtn.data(CONST.attributes.pid, productID);
    $deleteConfirmBtn.data(CONST.attributes.action, actionUrl);
    $deleteConfirmBtn.data(CONST.attributes.uuid, uuid);

    $productToRemoveSpan.empty().append(productName);
    $deleteConfirmBtn.first().click();

    if ($(selectors.cartError).length !== 0) {
        $(selectors.cartError).empty();
    }

    if ($(CONST.selectors.page).data(CONST.attributes.action) === CONST.pipelines.cartShow) {
        location.reload();
    } else {
        $(selectors.minicartWrapper).trigger(CONST.events.minicartTriggerPopover);
    }
}

/**
 * @private onCartDeleteConfirmationClick
 * @param {MouseEvent} evt - Click Event
 */
function onCartDeleteConfirmationClick(evt) {
    evt.preventDefault();

    const $self = $(this);
    const productID = $self.data(CONST.attributes.pid);
    let url = $self.data(CONST.attributes.action);
    const uuid = $self.data(CONST.attributes.uuid);
    const urlParams = {
        pid: productID,
        uuid: uuid
    };

    url = helpers.appendToUrl(url, urlParams);

    $(selectors.modalBackdrop).remove();
    $.spinner().start();
    $.ajax({
        url: url,
        type: 'get',
        dataType: 'json',
        success: function (data) {
            const hasGiftCertificateItems = data.basket.giftCertificateItems && data.basket.giftCertificateItems.length !== 0;

            $body.trigger(CONST.events.productAfterRemoveFromCart, {
                data: data
            });

            if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                for (let i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                    $('.uuid-' + data.toBeDeletedUUIDs[i]).remove();
                }
            }
            $('.uuid-' + uuid).remove();
            if (!data.basket.hasBonusProduct) {
                $('.bonus-product').remove();
            }
            $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
            helpers.updateCartTotals(data.basket);
            helpers.updateApproachingDiscounts(data.basket.approachingDiscounts);
            $body.trigger(CONST.events.setShippingMethodSelection, data.basket);
            $(selectors.modalBackdrop).remove();
            $('#removeProductModal').modal(CONST.events.hide);
            helpers.validateBasket(data.basket);

            const $addChequeBtn = $(selectors.cheque.addChequeBtn).length > 0 ? $(selectors.cheque.addChequeBtn) : false;
            const $chequeProductLine = $(selectors.cheque.chequeProductLine).length > 0 ? $(selectors.cheque.chequeProductLine) : false;
            if ($addChequeBtn) {
                if (data.canAddWaardecheck) {
                    $addChequeBtn.removeClass(CONST.classes.dNone);
                } else {
                    $addChequeBtn.addClass(CONST.classes.dNone);
                }

                if (!data.hasWaardecheck && $chequeProductLine[0]) {
                    $chequeProductLine.remove();
                }
            }
            if(data.forceRefresh && $(CONST.selectors.page).data(CONST.attributes.action) !== CONST.pipelines.cartShow) {
                location.reload();
            }
            $.spinner().stop();
        },
        error: function (err) {
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else {
                helpers.createErrorNotification(err.responseJSON.errorMessage);
                $.spinner().stop();
            }
        }
    });
}

/**
 * Updates the quantity field base on an "increase" or "decrease" direction
 * @param {HTMLElement} $self - The jQuey element
 */
function quantityChanger($self) {
    var $qty = $self.closest(selectors.product.quantityForm).find('.quantity');
    var direction = $self.data('direction');
    var currQty = parseInt($qty.val(), 10);
    var maxQty = $qty.data('qty-max');
    var minQty = $qty.data('qty-min');
    var isWelcomeGiftVoucher = $qty.data('is-gift-voucher');
    var newQty;

    if (direction === 'increase' && currQty !== maxQty) {
        newQty = currQty + 1;
        $qty.val(newQty).trigger(CONST.events.change);
    }

    if (direction === 'decrease' && currQty !== minQty) {
        newQty = currQty - 1;
        $qty.val(newQty).trigger(CONST.events.change);
    }

    if (!isWelcomeGiftVoucher) {
        $self.closest(selectors.product.quantityForm).find('.js-quantity-changer').removeAttr(CONST.attributes.disabled);
    }

    if (newQty >= maxQty || newQty === minQty) {
        $self.attr(CONST.attributes.disabled, CONST.attributes.disabled);
    }

    helpers.toggleDisabledStateOnIncreaseButton(
        isWelcomeGiftVoucher || newQty >= maxQty,
        $self.closest(selectors.product.quantityForm)
        .find('.js-quantity-changer[data-direction="increase"]')
    );
}

/**
 * On change for quantity input
 * @returns {void}
 */
function onChangeProductQuantity() {
    const $self = $(this);
    var preSelectQty = $self.data('pre-select-qty');
    var quantity = $self.val();
    var productID = $self.data(CONST.attributes.pid);
    var url = $self.data(CONST.attributes.action);
    var uuid = $self.data(CONST.attributes.uuid);
    var $quantityForm = $self.closest(selectors.product.quantityForm);
    var $amount = $quantityForm.find('.js-quantity-amount');

    var urlParams = {
        pid: productID,
        quantity: quantity,
        uuid: uuid
    };
    url = helpers.appendToUrl(url, urlParams);

    $self.parents('.card').spinner().start();

    $.ajax({
        url: url,
        type: 'get',
        context: this,
        dataType: 'json',
        success: function (data) {
            if (data.hasBonusProduct !== previousBonusState) {
                if ($(CONST.selectors.page).data(CONST.attributes.action) === CONST.pipelines.cartShow) {
                    location.reload();
                } else {
                    previousBonusState = !!($('.minicart').find('.bonus-product-line-item').length !== 0);

                    $(selectors.minicartWrapper).trigger(CONST.events.minicartTriggerPopover);

                    previousBonusState = data.hasBonusProduct;
                }
            }

            $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
            $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
            helpers.updateCartTotals(data);
            helpers.updateApproachingDiscounts(data.approachingDiscounts);
            if (data.productType !== 'giftcard') {
                helpers.updateAvailability(data, uuid);
            }
            $(CONST.selectors.body).trigger(CONST.events.productChangeQuantity, {
                data: data,
                productID: productID,
                quantity: quantity
            });
            helpers.validateBasket(data);
            $(this).data('pre-select-qty', quantity);
            $amount.html(quantity);

            var $addChequeBtn = $(selectors.cheque.addChequeBtn).length > 0 ? $(selectors.cheque.addChequeBtn) : false;
            var $chequeProductLine = $(selectors.cheque.chequeProductLine).length > 0 ? $(selectors.cheque.chequeProductLine) : false;
            if ($addChequeBtn) {
                if (data.canAddWaardecheck) {
                    $addChequeBtn.removeClass(CONST.classes.dNone);
                } else {
                    $addChequeBtn.addClass(CONST.classes.dNone);

                    if (!data.hasWaardecheck && $chequeProductLine[0]) {
                        $chequeProductLine.remove();
                    }
                }
            }

            $.spinner().stop();
        },
        error: function (err) {
            var response = err && err.responseJSON ? err.responseJSON : null;

            if (!response) {
                return;
            }

            // assumes that if there is a message, that its a warning
            if (response.errorMessage) {
                window.pubSub.publish(CONST.pubSubEvents.showNotification, {
                    result: 'error',
                    msg: response.errorMessage
                });
            }

            if (response.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else if (response.errorMessage) {
                helpers.createErrorNotification(err.responseJSON.errorMessage);
                $(this).val(parseInt(preSelectQty, 10));
                $amount.html(parseInt(preSelectQty, 10));
            }

            $.spinner().stop();
        }
    });
}

module.exports = function () {
    $doc.ready(() => {
        $page = $(CONST.selectors.page);
        $search = $(selectors.search);

        $minicartPopover = $(selectors.minicartPopover);
        $minicartQuantity = $(selectors.minicartQuantity);

        // bonus state gets updated when changing quantity on frontend
        // see onChangeProductQuantity
        previousBonusState = $(selectors.product.bonusProduct).length > 0;
    });

    /**
     * Triggers the minicart popover and populates this with data.
     * Triggered when updating the numeric value inside the minicart symbol and when the mouse enters the minicart.
     */
    function updateMiniCartPopOver() {
        const url = $minicart.data(CONST.attributes.actionUrl);

        if (url) {
            $body.spinner().start();

            if (cwUtil.isSmallScreen() === true) {
                $body.animate({ scrollTop: 0 }, 1000);
            }

            $.get(url, function (data) {
                $minicartPopover.empty();
                $minicartPopover.append(data);
                $body.spinner().stop();
            });
        }
    }

    /**
     * Triggers the minicart popover and populates this with data.
     * Triggered when updating the numeric value inside the minicart symbol and when the mouse enters the minicart.
     */
    function triggerMiniCartPopOver() {
        const onCartPage = $(selectors.cartPage).length > 0;

        // Refresh the page if the minicart is updated while on the cart page,
        // otherwise the cart and minicart won't be in sync.
        if (onCartPage) {
            setTimeout(function () {
                $.spinner().start();
                window.location.href = window.location.href;
            }, 100);
        } else {
            const count = parseInt($minicartQuantity.text(), 10);

            if (count !== 0 && $minicartPopover[0] && !$minicartPopover.hasClass(CONST.classes.show)) {
                $minicartPopover.addClass(CONST.classes.show);
                updateMiniCartPopOver();
            }
        }
    }

    /**
     * Empties and removes the minicart popover
     */
    function emptyMiniCart() {
        if ($minicartPopover){
            $minicartPopover.empty();
            $minicartPopover.removeClass(CONST.classes.show);
        }
    }

    $minicart.on(events.countUpdate, function (evt, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            $minicartQuantity.text(count.quantityTotal);
            triggerMiniCartPopOver();
        }
    });

    $minicartLink.on([CONST.events.mouseenter, CONST.events.focusin, CONST.events.touchstart].join(' '), function () {
        // Don't show the minicart if the search suggestions are visible OR if the current page is cart
        if ($search.filter(CONST.classes.active)[0] ||
            $page.data(CONST.attributes.action) === CONST.pipelines.cartShow ||
            $page.data(CONST.attributes.action) === CONST.pipelines.checkoutStep) {
            return;
        }

        triggerMiniCartPopOver();
    });

    $body.on([CONST.events.touchstart, CONST.events.click].join(' '), function (e) {
        if ($minicart.has(e.target).length <= 0) {
            emptyMiniCart();
        }
    });

    $minicart.on(CONST.events.focusout, function (e) {
        if ((e.type === CONST.events.focusout && $minicart.has(e.target).length > 0)
            || $body.hasClass(CONST.classes.modalOpen)) {
            e.stopPropagation();
            return;
        }

        emptyMiniCart();
    });

    $minicart.on(CONST.events.click, selectors.minicartCloseButton, emptyMiniCart);
    $minicart.on(CONST.events.minicartTriggerPopover, updateMiniCartPopOver);

    /**
     * Deleting product from cart
     */
    $body.on(CONST.events.click, selectors.product.removeProductBtn, onRemoveProduct);
    $body.on(CONST.events.click, selectors.deleteCartConfirmationBtn, onCartDeleteConfirmationClick);
    $body.on(CONST.events.change, selectors.product.quantityInput, onChangeProductQuantity);

    /**
     * Changing product Quantity
     */
    $doc.on(CONST.events.click, selectors.product.changeQuantity, function quantityEventClosure() {
        // used for passing the change quantity element to the function
        quantityChanger($(this));
    });
};
